import { flow, get, hasIn, isNil, defaultTo } from 'lodash';
import { connect } from 'react-redux';
import { AppState, AppThunkDispatch } from 'src/store';
import {
  ConfigurableGridColumnDef,
  ConfigurableGridValueProps,
  ConfigurableGridOwnProps,
} from 'src/components/ConfigurableGrid/ConfigurableGrid.types';

import { makePrintSensitive } from 'src/components/higherOrder/Print/PrintSenstive';
import {
  resetGroupBySelection,
  setGroupBySelection,
  setFloorsetSelection,
  updateConfigurableGridConfig,
  refreshConfigurableGridData,
  submitConfigurableGridPayload,
} from './ConfigurableGrid.slice';
import { bindActionCreators } from 'redux';
import { FabType, getfabProps, withFab } from '../higherOrder/withFab';
import { updateAssortmentPlan } from 'src/pages/AssortmentBuild/StyleEdit/StyleEdit.actions';
import { isDataLoaded } from 'src/services/pivotServiceCache';
import { ConfigurableGrid } from './ConfigurableGrid';
import {
  getViewDefnData,
  getGroupByDropdownProps,
  getFloorsetDropdownProps,
  getConfigurableGridData,
  ConfigurableGridDataSelectorProps,
  getLeafIdentifier,
} from './ConfigurableGrid.selectors';
import { ConfigurableGridDispatchProps } from './ConfigurableGrid.types';
import { CoarseEditPayload } from 'src/dao/pivotClient';
import { BasicPivotItem } from 'src/worker/pivotWorker.types';
import { IS_PUBLISHED } from 'src/utils/Domain/Constants';
import { AdornmentType } from 'src/services/configuration/codecs/viewdefns/literals';
import { ParseResult, parseConfigureConfig } from '../Configure/Configure';
import { Option } from 'src/components/Configure/ConfigureModal';
import { getConfigureSelections } from 'src/components/Subheader/Subheader.selectors';
import { updateConfigureSelections } from '../Subheader/Subheader.slice';
import { getSectionContextObj } from 'src/utils/Domain/Perspective';
import { isWorklistActive } from 'src/services/configuration/codecs/confdefnComponents';
import { makePopoverSensitive } from 'src/components/AssortmentStyleDetailsPopover/AssortmentStyleDetailsPopover';
import { withRouter } from 'src/components/higherOrder/withRouter';
import { addSelectedItemsToCart } from 'src/pages/AssortmentBuild/AssortmentAdd/AssortmentAdd.actions';
import {
  getAssortmentCartLink,
  getSelectedItems,
} from 'src/pages/AssortmentBuild/AssortmentAdd/AssortmentAddBySearch/AssortmentAddBySearch.selectors';
import { toggleSelectionsInCart } from './ConfigurableGrid.actions';
import { ScopeCreateRequest, TopMembers } from 'src/services/Scope.client';
import { narrowWorkflow } from '../Mfp/MfpScopeSelector/MfpScopebar.container';
import { newScope } from 'src/state/scope/Scope.actions';
import { makelookBackPredicate } from 'src/utils/Pivot/Filter';
import { cascadingFilter } from 'src/utils/Tree/ObjectArrayTree';
import { setPreviousView } from 'src/pages/NavigationShell/NavigationShell.slice';

function mapStateToProps(state: AppState, ownProps: ConfigurableGridOwnProps): ConfigurableGridValueProps {
  const {
    viewDefnState,
    gridDataState: viewDataState,
    viewDefn,
    unmodifiedViewDefn,
  } = state.pages.assortmentBuild.configurableGrid;
  const { showFlowStatus, fabType = FabType.none, showLookBackPeriod } = ownProps;
  const fabProps = getfabProps(state, fabType);
  const dataLoaded = isDataLoaded(viewDataState);

  // TODO: if possible move this logic down into the component to cut down on the number of props passed out of this function
  const columnDefs = isNil(viewDefn) ? [] : (viewDefn.columns as ConfigurableGridColumnDef[]);
  const actions = isNil(viewDefn) ? null : viewDefn.actions;
  const gridRowHeight: number = get(viewDefn, 'main.rowHeight', 30);
  const groupRowHeight: number = get(viewDefn, 'main.groupRowHeight', 30);

  const massEditConfig = hasIn(viewDefn, 'massEditConfig') ? viewDefn.massEditConfig : undefined;
  const showPublish = hasIn(viewDefn, 'showPublish') ? viewDefn.showPublish : undefined;
  const publishText = hasIn(viewDefn, 'publishText') ? viewDefn.publishText : 'Publish';
  const publishAttribute = hasIn(viewDefn, 'publishAttribute') ? viewDefn.publishAttribute : IS_PUBLISHED;
  const hideUnpublish = hasIn(viewDefn, 'hideUnpublish') ? viewDefn.hideUnpublish : undefined;
  const unpublishText = hasIn(viewDefn, 'unpublishText') ? viewDefn.unpublishText : undefined;
  const updateCoordinateMap = hasIn(viewDefn, 'updateCoordinateMap') ? viewDefn.updateCoordinateMap : undefined;
  const salesAdjustmentConfig = isNil(viewDefn) ? {} : viewDefn.salesAdjustment;
  const configurablePostAction = get(viewDefn, 'configurablePostAction', {});
  const adornments = get<AdornmentType[]>(viewDefn, 'adornments', []);
  const leafIdProp = getLeafIdentifier(ownProps);

  const { dependentCalcs, companionSortOptions, defaultCompanionSortField, clientActionHandlers } = getViewDefnData(
    state
  );
  const floorsetDropdownProps = getFloorsetDropdownProps(state);
  const selectorProps: ConfigurableGridDataSelectorProps = {
    showFlowStatus,
    leafId: leafIdProp,
  };
  const ungroupedData: BasicPivotItem[] = getConfigurableGridData(state, selectorProps);
  // use configure if available, otherwise try fallback to groupby
  let configureOptions: ParseResult | undefined = undefined;
  let groupByDropdownProps: ReturnType<typeof getGroupByDropdownProps> | undefined = undefined;
  let configureSelections: Option[] | undefined = undefined;
  if (hasIn(viewDefn, 'configure')) {
    const parseResult = parseConfigureConfig(viewDefn.configure);
    configureOptions = {
      ...parseResult,
    };
    configureSelections = getConfigureSelections(state);
  } else {
    groupByDropdownProps = getGroupByDropdownProps(state);
  }

  const context = getSectionContextObj();
  const undoBtnConf = get(context, 'undoBtn', undefined);
  const showUndo = !state.print.isPrintMode && !isNil(undoBtnConf) && defaultTo(ownProps.showUndoBtn, false);
  const assortmentCartLink = getAssortmentCartLink(state);
  const cartCount = getSelectedItems(state).length;
  const groupBySelection = state.pages.assortmentBuild.configurableGrid.groupBySelection;
  const hasSameGroupbyOptions =
    groupByDropdownProps &&
    groupBySelection &&
    groupBySelection.option?.dataIndex &&
    groupByDropdownProps.options.find(
      (option, idx) => option.dataIndex === groupBySelection.option.dataIndex && groupBySelection.selectedIndex === idx
    );
  const lookBackPredicate = makelookBackPredicate(state.subheader.lookBackPeriod);

  return {
    identifier: ownProps.keys.idProp, // not a good name, but this is used for linking components (f/e companion list and grid)
    // idProp is used for grid level row identification (for example, row selection)
    leafIdProp,
    flowStatus: showFlowStatus ? state.subheader.flowStatus : [],
    search: state.subheader.search || '',
    groupBySelection: hasSameGroupbyOptions ? groupBySelection : undefined,
    favoritesList: state.subheader.favoritesList || [],
    fab: fabProps,
    viewDefnState: viewDefnState,
    dataLoaded,
    configureSelections,
    configureOptions,
    columnDefs,
    massEditConfig,
    showPublish,
    publishText,
    publishAttribute,
    hideUnpublish,
    unpublishText,
    updateCoordinateMap,
    dependentCalcs,
    gridRowHeight,
    groupRowHeight,
    companionSortOptions,
    defaultCompanionSortField,
    unmodifiedViewDefn,
    configuratorViewDefn: viewDefn,
    clientActionHandlers,
    groupByDropdownProps,
    floorsetDropdownProps,
    data: showLookBackPeriod ? cascadingFilter(ungroupedData, lookBackPredicate) : ungroupedData, //always pass in flat data, grid manages grouping
    topAttributesData: state.pages.assortmentBuild.configurableGrid.topAttributesData,
    salesAdjustmentConfig,
    adornments,
    configurablePostAction,
    scopeSelection: state.scope.selections,
    viewDataState,
    showUndo,
    isWorklistActive: isWorklistActive(state),
    allowWorklistFunctionality: ownProps.allowWorklistFunctionality,
    assortmentCartLink,
    cartCount,
    actions,
    showLookBackPeriod,
  };
}

function mapDispatchToProps(
  dispatch: AppThunkDispatch,
  ownProps: ConfigurableGridOwnProps
): ConfigurableGridDispatchProps {
  return {
    dispatch,
    ...bindActionCreators({ setGroupBySelection, resetGroupBySelection, setFloorsetSelection }, dispatch),
    onUpdateConfig: (config: any) => {
      dispatch(updateConfigurableGridConfig(config));
    },
    updateAssortmentPlan: () => {
      dispatch(updateAssortmentPlan('PlanQueue'));
    },
    onRefreshConfigurableGridData: () => {
      dispatch(refreshConfigurableGridData());
    },
    submitPayload: async (payload: CoarseEditPayload) => {
      await submitConfigurableGridPayload(payload);
    },
    updateConfigureSelections(selections: Option[]) {
      dispatch(updateConfigureSelections(selections));
    },
    addSelectedItemsToCart() {
      dispatch(addSelectedItemsToCart(ownProps.cartItemType, ownProps.level));
    },
    toggleSelections(items) {
      dispatch(toggleSelectionsInCart(ownProps, items));
    },
    createMfpScope: (topMembers: TopMembers, workflow: string) => {
      const request: ScopeCreateRequest = {
        initParams: { type: 'with-wp' },
        workflow: narrowWorkflow(workflow),
        anchor: topMembers,
      };
      return dispatch(() => {
        return dispatch<Promise<unknown>>(newScope(request));
      });
    },
    setPreviousView: (url: string) => {
      dispatch(setPreviousView(url));
    },
  };
}

const sensitiveView = flow(() => ConfigurableGrid, withFab, withRouter, makePopoverSensitive, makePrintSensitive)();
export default connect(mapStateToProps, mapDispatchToProps)(sensitiveView);
